import React from 'react'
import { BasicSearch } from '../components/search/basicSearch'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { AppLocale } from '@hrk/types'
import { Seo } from '../components/seo/Seo'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { DownloadContextProvider } from '../context/DownloadContext'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'

const HigherEducationInsitituteSearch: React.FC = () => {
  const currentSlug = '/en/higher-educations-institutes-search'
  const currentLocale: AppLocale = 'en'
  const seoTitle = 'Higher education institute search'
  const seoDescription = 'The search for higher education institutes on hoch und weit'

  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: seoTitle, type: 'EditorialPages' })

  // TODO: redirect to '/search?t=Universities'. @see frontend-web/huw-gatsby/src/pages/weiterbildung-interessentest.tsx

  // see HOW-608, has a special social title and description
  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <DownloadContextProvider>
        <LayoutWrapper
          currentTitle={seoTitle}
          currentLocale={currentLocale}
          currentSlug={currentSlug}
          currentPath={currentPagePath}
          otherLocales={[{ locale: 'de', slug: '/hochschulsuche/' }]}
        >
          <BasicSearch category={'Universities'} />
        </LayoutWrapper>
      </DownloadContextProvider>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/higher-educations-institutes-search/`} />
}

export default HigherEducationInsitituteSearch
